import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { showToastSuccess, showToastError } from "@/utils/toastFunctions";
import { toastMessages } from "@/constants/toast-messages";
import { Cart } from "@/interfaces/account/Home/Cart";

interface CartContextType {
  cartItemCount: number;
  cartItems: Cart[];
  addItemToCart: (item: Cart, is_detail_page?: boolean) => void;
  removeItemFromCart: (item: Cart) => void;
  clearCart: () => void;
  getCartItems: () => Cart[];
  updateCartItemQuantity: (item: Cart, newQuantity: number) => void;
}

// Create the context with default values
const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [cartItems, setCartItems] = useState<Cart[]>([]);
  const [cartItemCount, setCartItemCount] = useState<number>(0);

  // Sync cart state across tabs
  useEffect(() => {
    // Initial load
    const loadCartFromStorage = () => {
      const storedCartItems = JSON.parse(localStorage.getItem("cart") || "[]");
      setCartItems(storedCartItems);
      setCartItemCount(
        storedCartItems.reduce(
          (sum: number, item: Cart) => sum + item.quantity,
          0
        )
      );
    };

    // Listen for storage changes from other tabs
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === "cart") {
        loadCartFromStorage();
      }
    };

    // Load initial cart
    loadCartFromStorage();

    // Add storage event listener
    window.addEventListener("storage", handleStorageChange);

    // Custom event for same-tab updates
    window.addEventListener("cartUpdate", loadCartFromStorage);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("cartUpdate", loadCartFromStorage);
    };
  }, []);

  const updateCartStorage = (updatedCartItems: Cart[]) => {
    localStorage.setItem("cart", JSON.stringify(updatedCartItems));
    // Dispatch custom event for same-tab updates
    window.dispatchEvent(new Event("cartUpdate"));
  };

  const addItemToCart = (item: Cart, is_detail_page?: boolean) => {
    const existingCartItem = cartItems.find(
      (cartItem) => cartItem.product.id === item.product.id
    );

    const currentQuantity = existingCartItem ? existingCartItem.quantity : 0;
    let newQuantity = is_detail_page
      ? item.quantity
      : currentQuantity + item.quantity;

    if (newQuantity > item.product.in_stock_count) {
      showToastError(
        `Only ${item.product.in_stock_count} items available in stock.`
      );
      return;
    }

    const updatedCartItems = [
      ...cartItems.filter(
        (cartItem) => cartItem.product.id !== item.product.id
      ),
      { ...item, quantity: newQuantity },
    ];

    updateCartStorage(updatedCartItems);
    showToastSuccess(toastMessages.productAddedToCart);
  };

  const removeItemFromCart = (item: Cart) => {
    const updatedCartItems = cartItems.filter(
      (cartItem) => cartItem.product.id !== item.product.id
    );
    updateCartStorage(updatedCartItems);
  };

  const clearCart = () => {
    updateCartStorage([]);
  };

  const getCartItems = (): Cart[] => {
    return JSON.parse(localStorage.getItem("cart") || "[]");
  };

  const updateCartItemQuantity = (item: Cart, newQuantity: number) => {
    if (newQuantity < 1 || newQuantity > item.product.in_stock_count) {
      showToastError(
        `Quantity must be between 1 and ${item.product.in_stock_count}`
      );
      return;
    }

    const updatedCartItems = cartItems.map((cartItem) =>
      cartItem.product.id === item.product.id
        ? { ...cartItem, quantity: newQuantity }
        : cartItem
    );

    updateCartStorage(updatedCartItems);
  };

  return (
    <CartContext.Provider
      value={{
        cartItemCount,
        cartItems,
        addItemToCart,
        removeItemFromCart,
        getCartItems,
        clearCart,
        updateCartItemQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

// Custom hook to use the CartContext
export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
